import { booking } from '@/api/booking'
import { commonServices } from '@/api/common-services'
import { Workbook } from 'exceljs'
import 'devextreme/dist/css/dx.light.css'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import jquery from 'jquery'
const dataGridRef = 'tablePackage'
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
    },
    data() {
        return {
            isLoading: false,
            currentPage:1,
            perPage: 10,
            dataGridRef: 'tablePackage',
            optionPerPage: [10, 20, 50],
            configTable: [
                {
                caption: 'Tài khoản',
                field: 'UserName',
                isShow: true,
                alignment: 'center',
                width: '20%',
                },
                {
                    caption: 'Tên người dùng',
                    field: 'CashierName',
                    isShow: true,
                    alignment: 'center',
                    width: '20%',
                },
                {
                    caption: 'Caddy',
                    field: 'Caddy',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    cellTemplate: 'Caddy',
                },
                {
                    caption: 'Thu Ngân',
                    field: 'Cashier',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    cellTemplate: 'Cashier',
                },
                {
                    caption: 'Kinh doanh',
                    field: 'Business',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    cellTemplate: 'Business',
                },
                {
                    caption: 'Lễ tân',
                    field: 'Receptionist',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    cellTemplate: 'Receptionist',
                },
                {
                    caption: 'Kế toán',
                    field: 'Accountant',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    cellTemplate: 'Accountant',
                },
                {
                    caption: 'GM',
                    field: 'GM',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    cellTemplate: 'GM',
                },
            ],
            items: [],
            dataTemp: [],
            listRoll: [
                {
                    label:"Caddy",
                    value: "Caddy"
                },
                {
                    label:"Thu Ngân",
                    value: "Cashier"
                },
                {
                    label:"PM",
                    value: "PM"
                },
                {
                    label:"Kinh doanh",
                    value: "Business"
                },
                {
                    label:"Lễ Tân",
                    value: "Receptionist"
                },
                {
                    label:"Kế toán",
                    value: "Accountant"
                }
            ],
            keyWord: {
                UserName: '',
                Roles: []
            },
            listRoles: [],
            listUser: []
        }
    },
    computed: {
        dataGrid() {
          return this.$refs[dataGridRef].instance
        },
    },
    async created() {
        await this.getListRoles()
        await this.getListUser()
    },
    methods: {
        async updateUser() {
            var dataUpdateUser = []
            this.items.forEach(x => {
                var RoleTemp = []
                if(x.Receptionist.IsRole == true) {
                    RoleTemp.push(x.Receptionist.Id)
                }
                if(x.Caddy.IsRole == true) {
                    RoleTemp.push(x.Caddy.Id)
                }
                if(x.Cashier.IsRole == true) {
                    RoleTemp.push(x.Cashier.Id)
                }
                if(x.Business.IsRole == true) {
                    RoleTemp.push(x.Business.Id)
                }
                if(x.Accountant.IsRole == true) {
                    RoleTemp.push(x.Accountant.Id)
                }
                if(x.GM.IsRole == true) {
                    RoleTemp.push(x.GM.Id)
                }
                dataUpdateUser.push({
                    Id: x.Id,
                    Role: RoleTemp
                })
            })
            const body = {
                UserRoles: dataUpdateUser
            }
            await commonServices.api_URO01(body).then(res => {
                this.showResToast(res)
                if(res.Status === '200') {
                    this.getListRoles()
                    this.getListUser()
                }
            })
        },
        search() {
            if(this.keyWord.UserName) {
                var listUser = []
                if(this.keyWord.UserName) {
                    this.dataTemp.forEach(x => {
                        if(x.UserName.toLocaleUpperCase().includes(this.keyWord.UserName.toLocaleUpperCase())) {
                            listUser.push(x)
                        }
                    })
                }
                this.items = listUser
            } else {
                this.items = this.dataTemp
            }
        },
        async getListRoles() {
            await commonServices.api_RRO01().then(res => {
                this.listRoles = res.Data.Roles
                this.listUserRoles = res.Data.UserRoles
            })
        },
        async getListUser() {
            await booking.api_RCS01().then(res => {
                this.listUser = res.Data.Cashier
                this.listUser.forEach(x => {
                    this.listRoles.forEach(y => {
                        if(y.RoleName === 'Thu ngân') {
                            x.Cashier = {
                                RoleName: y.RoleName,
                                Id: y.Id,
                                IsRole: false
                            }
                        }
                        if(y.RoleName === 'Kinh doanh') {
                            x.Business = {
                                RoleName: y.RoleName,
                                Id: y.Id,
                                IsRole: false
                            }
                        }
                        if(y.RoleName === 'Lễ tân') {
                            x.Receptionist = {
                                RoleName: y.RoleName,
                                Id: y.Id,
                                IsRole: false
                            }
                        }
                        if(y.RoleName === 'Caddie') {
                            x.Caddy = {
                                RoleName: y.RoleName,
                                Id: y.Id,
                                IsRole: false
                            }
                        }
                        if(y.RoleName === 'Kế toán') {
                            x.Accountant = {
                                RoleName: y.RoleName,
                                Id: y.Id,
                                IsRole: false
                            }
                        }
                        if(y.RoleName === 'GM') {
                            x.GM = {
                                RoleName: y.RoleName,
                                Id: y.Id,
                                IsRole: false
                            }
                        }
                    })
                })
                this.listUser.forEach(x => {
                    this.listUserRoles.forEach(y => {
                        if(x.Id === y.Id) {
                            if(y.Role.length > 0) {
                                y.Role.forEach(z => {
                                    if(x.Cashier.Id === z ) {
                                        x.Cashier.IsRole = true
                                    }
                                    if(x.Business.Id === z) {
                                        x.Business.IsRole = true
                                    }
                                    if(x.Receptionist.Id === z) {
                                        x.Receptionist.IsRole = true
                                    }
                                    if(x.Caddy.Id === z) {
                                        x.Caddy.IsRole = true
                                    }
                                    if(x.Accountant.Id === z) {
                                        x.Accountant.IsRole = true
                                    }
                                    if(x.GM.Id === z) {
                                        x.GM.IsRole = true
                                    }
                                })
                            }
                        }
                    })
                })
                this.dataTemp = this.listUser
                this.items = this.listUser
            })
        }
    },
}